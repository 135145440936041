.modalBody {
	width: 444px;
	border-radius: 12px;
	.background {
		padding: 16px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.modalHeader {
		background: #8E173E;
	}
}

.businessForm {
	width: 100% !important;
	margin-right: auto;
	padding: 0 24px;
	& > form {
		display: flex;
		flex-direction: column;
		padding-top: 24px;
		padding-bottom: 24px;
		height: 360px;
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		& > div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}

.linkDetails {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #ffffff;
}

.modalContent {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	padding: 0 24px;
	.modalDetails {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 12px;
		padding-top: 12px;
		border-bottom: 1px solid #e0e0e0;
	}
}

.modalFooter {
	padding: 12px 24px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: #f5f5f5;
	.cancel {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #0a0a0a;
		padding: 8px 10px;
		background: #ffffff;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		cursor: pointer;
	}
	.deactivate {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		padding: 8px 12px;
		background: #8E173E;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		cursor: pointer;
		margin-left: 16px;
	}

	.notClickable {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #9e9e9e;
		background: #ededed;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		margin-left: 16px;
		padding: 8px 12px;
	}
}

.radioSelection {
	display: flex;
	flex-direction: row;
	align-items: center;
	.radioLabel {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: -0.006em;
		color: #424242;
	}
}

.businessType {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: -0.006em;
	color: #0a0a0a;
}

.inputField {
	color: #757575 !important;
	font-size: 12px;

	& input,
	textarea {
		padding: 1rem !important;
		color: #757575 !important;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242 !important;
	}

	& label {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: -0.006em;
		color: #424242;
		z-index: 10000000;
		margin-top: -0.5rem;
	}
}

.options {
	padding: 8px 24px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
}

.optiontext {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
	background: #ffffff;
	border: 1px solid #ededed;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
}

.dropDown {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
	background: #ffffff;
	border-color: #616161 !important;
	border-width: 0.8px !important;
	border-radius: 10px;
	margin: 1;
	width: 385;
}

.inputLabel {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.006em;
	/* Neutral/90 */
	color: #424242;
	margin-bottom: -1.2rem !important;
}
