.tabContainer {
	margin: 32px 0px 24px 0px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 74px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	width: 100%;
}

.top_Container {
	width: 100%;
	height: 165px;
	background: #ffffff;
	border-radius: 8px;
	padding: 30px 0px;
	display: flex;
	align-items: center;
	overflow-x: scroll;
	overflow-y: hidden;
}
.top_Container_box {
	width: 100%;
	height: 105px;
	background: #ffffff;
	border: 0.5px solid rgba(206, 206, 205, 0.4);
	box-shadow: 0px 1px 2px rgba(0, 43, 18, 0.08);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px 20px;

	h5 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 16px;
		color: #4b5563;
		margin: 0;
		padding: 0;
	}
}
.top_Container_box_left {
	p {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		align-items: center;
		color: #8E173E;
		margin: 0;
		padding: 0;
	}
}
.top_Container_box_leftdiv {
	display: flex;
	h4 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		color: rgba(10, 9, 3);
		margin: 0;
		padding: 0;
	}
	span {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		color: rgba(10, 9, 3, 0.5);
		margin: 0;
		padding: 0;
	}
}

.activeMenuItem {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	background-color: #fff;
	margin: 0 1rem;

	.activeItemColor {
		color: #0a0903;
		border-bottom: 3.5px solid #8E173E;
		border-radius: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
	}
}
.subMenuItem {
	padding: 0.5rem;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #424242;
	margin-bottom: 5px;
}

.subMenuItem:hover {
	background: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}
