//NEW DESIGNS

.signWrapper {
	display: flex;
	height: 100vh;
}

.leftwrapper {
	flex: 1;
	padding: 30px;
}

.rightwrapper {
	flex: 1;
}

.rightwrapper_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image {
	margin-bottom: 10px;
	cursor: pointer;
}

.formwrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 106px;
}

.form_heading {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #272501;
	margin-bottom: 10px;
}

.form_p {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #858481;
}

.businessForm {
	padding: 0 140px;
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.signInButton {
	width: 441px;
	height: 56px;
	background: #8E173E;
	border-radius: 4px;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 4px;
	border: none;
	margin-top: 36px;

	&:disabled {
		background-color: #969696;
	}
}

.forgetPassword {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	color: #272501;
}

.forgetPasswordspan {
	color: #8E173E;
	cursor: pointer;
}


.passwordInp {
	margin: 20px 0;
	position: relative;
	width: 100%;
}

.inputImg {
	cursor: pointer;
	position: absolute;
	width: 20px;
	right: 30px;
	top: 35px;
}

@media only screen and (max-width: 1100px) {
	.signinForm {
		margin: 0 auto;
	}

	.rightwrapper {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	.signinForm {
		width: 100%;
	}

	.businessForm {
		padding: 0 40px;
	}
}

@media only screen and (max-width: 600px) {
	.businessForm {
		padding: 0 10px;
	}
}