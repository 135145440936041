.dashbordContainer {
	margin: 10px 0;
	min-height: 100vh;
}

.filterSubheading {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.filterBody {
	margin-top: 10px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.modalwrapper {
	width: 518px;
	// height: 574px;
	background: #ffffff;
}
.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
}
.modalheadh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}
.modalcontent {
	padding: 34px 32px;
	width: 100%;
}
.modalcontentp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #272501;
}
.modalcontentbox {
	margin-top: 29px;
	padding: 17px 30px;
	background: #f5faf7;
	border-radius: 5px;
	margin-bottom: 60px;
}
.modalcontentboxflex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.modalcontentboxp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #272501;
}
.modalselect {
	width: 100%;
	margin-bottom: 21px;
}
.select {
	width: 100%;
}
.inputLabel {
}
.modalFooter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 16px;
}
.cancel {
	width: 84px;
	height: 40px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #8E173E;
	margin-right: 16px;
	outline: none;
	border: 1px solid rgb(238, 238, 238);
	background: #ffffff;
	border-radius: 4px;
}
.fund {
	padding: 11px 24px;
	height: 40px;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;
}

.input {
	width: 100%;
	margin-bottom: 29px;
}

.modalupload {
	height: 315px;
	background: #ffffff;
	border: 1px solid #2b9558;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	button {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #8E173E;
		width: 160px;
		height: 40px;
		border: 1px solid #8E173E;
		border-radius: 4px;
		margin-top: 50px;
		background-color: #ffffff;
	}

	p {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #4b492b;
		margin: 0;
		padding: 0;
		margin-top: 15px;
	}
}

.modaluploadp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #070602;
	margin-top: 28px;
	display: flex;
	justify-content: flex-start;
}

.createLink {
	width: 118px;
	height: 40px;
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #cce6d7;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	cursor: pointer;
	.link {
		margin-right: 10px;
	}
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 38px;
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
}

.logdetails {
	width: 629px;
	height: 695px;
	margin: 0 auto;
	background: #ffffff;
	border: 1px solid rgba(206, 206, 205, 0.4);
	border-radius: 8px;
	overflow: hidden;
}
.logdetailscontainer {
	height: 650px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
}
.logdetailswrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
}
.logdetailswraph3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}
.logdetailswraph4 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.logdetailswraph6 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: rgba(10, 9, 3, 0.5);
}

.buttonWrapper {
	display: flex;
	align-items: center;
}

.approve {
	margin: 0;
	border: none;
	outline: none;
	width: 50px;
	height: 20px;
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.disapprove {
	margin: 0;
	border: none;
	outline: none;
	width: 50px;
	height: 20px;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #8E173E;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
}
