.transactionInfo {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	width: 50%;
	justify-content: flex-start;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.topTable {
	// width: 50%;
	margin: 0 auto;
	margin-top: 40px;
}

.detailsHeader {
	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	padding: 10px 20px;
}

.tableContent {
	background: #ffffff;
	padding-left: 20px;
	border-radius: 0px 0px 8px 8px;
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.customerInfo {
	display: flex;
	// align-items: center;
	flex-direction: column;
	padding: 14px 0px;
}

.detailsValue {
	font-family: 'HelveticaNeue';
	font-style: normal;
	// width: 40%;
	justify-content: flex-start;
	font-weight: bold !important;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.detailsKey {
	font-family: 'HelveticaNeue';
	font-style: normal;
	// width: 60%;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
	word-break: break-all;
}

.chargeBack {
	display: flex;
	gap: 30px;
	// justify-content: center;
	align-items: center;
	width: 100%;
}

.chargeBackbutton {
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	width: 220px;
	height: 48px;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 20px;
	margin-top: 40px;
}

.ml {
	margin-left: 0.5rem;
}

.modalwrapper {
	width: 518px;
	// height: 574px;
	background: #ffffff;
}
.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
}
.modalheadh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}
.modalcontent {
	padding: 34px 32px;
	width: 100%;
}
.modalcontentp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #272501;
}
.modalcontentbox {
	margin-top: 29px;
	padding: 17px 30px;
	background: #f5faf7;
	border-radius: 5px;
	margin-bottom: 60px;
}
.modalcontentboxflex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.modalcontentboxp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #272501;
}
.modalFooter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 16px;
}
.cancel {
	width: 84px;
	height: 40px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #8E173E;
	margin-right: 16px;
	outline: none;
	border: 1px solid rgb(238, 238, 238);
	background: #ffffff;
	border-radius: 4px;
}
.cancel:hover, .fund:hover {
	scale: 1.1;
	transition: all 250ms ease-in;
}
.fund {
	padding: 11px 24px;
	height: 40px;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;

	&:disabled {
		background-color: #969696;
	}
}

.input {
	width: 100%;
	margin-bottom: 29px;
}



@media only screen and (max-width: 801px) {
	.topTable {
		width: 100%;
	}

	.tableContent {
		background: #ffffff;
		padding-left: 20px;
		border-radius: 0px 0px 8px 8px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.customerInfo {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 14px 0px;
	}
}