.table {
  background: #ffffff;
  border: 1px solid #f3f4f4;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(177, 182, 183, 0.5);
  border-radius: 4px;
  margin-top: 9px;
  border-radius: 8px 8px 0px 0px;
}

.tableHead {
  background: #e1f0fc;
  padding: 9.5px 20px;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(177, 182, 183, 0.5);
  background: #ededed;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
}

.tableBody {
  padding-top: 28px 20px;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
}

.tableBodyHover {
  padding-top: 28px 20px;
  cursor: pointer;

  &:hover {
    background-color: #edfaf1;
  }
}

.newStaff {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.addStaff {
  width: 200px;
  background: #011a4b;
  border: 1px solid #ecf0f4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Steradian;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d0eaff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.filter {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* Input/Active/Placeholder */
  color: #b5b5bd;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;
  margin-left: 11px;
}

.paginatedSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ededed;
  border-radius: 0px 0px 8px 8px;
  padding: 10px;
}

.previous {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #a79b9b;
  padding-left: 12px;
}

.previousTrue {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #424242;
  padding-left: 12px;
}

.next {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  padding-right: 12px;
  padding-left: 28px;
}

.nextFalse {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  padding-right: 12px;
  padding-left: 28px;
  color: #a79b9b;
}

.pageNumber {
  font-family: Steradian;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #37474f;
}

.arrowDirections {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;
  background: #f8f8f8;
}

.noTransaction {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0a0a;
}

.pageIndex {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #424242;
}

.selection {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.006em;
  margin-left: 10px;
  padding: 4px;
  color: #0a0a0a;
  border: 0.8px solid #9e9e9e;
  box-sizing: border-box;
  border-radius: 4px;
}

@media only screen and (max-width: 900px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .table_wrapper {
    display: table;
    width: 100%;
  }
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  // max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  height: 800px;
}
.closeButton {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: red;
  font-weight: bolder;
}
.transactionInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.transactionsHeader {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  width: 50%;
  justify-content: flex-start;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
}

.topTable {
  // width: 50%;
  margin: 0 auto;
  margin-top: 40px;
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
  padding: 10px 20px;
}

.tableContent {
  background: #ffffff;
  padding-left: 20px;
  border-radius: 0px 0px 8px 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.customerInfo {
  display: flex;
  // align-items: center;
  flex-direction: column;
  padding: 14px 0px;
  margin: 2px 5px;
}

.detailsValue {
  font-family: "HelveticaNeue";
  font-style: normal;
  // width: 40%;
  justify-content: flex-start;
  font-weight: bold !important;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #424242;
}

.detailsKey {
  font-family: "HelveticaNeue";
  font-style: normal;
  // width: 60%;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #424242;
  word-break: break-all;
}

.chargeBack {
  display: flex;
  gap: 30px;
  // justify-content: center;
  align-items: center;
  width: 100%;
}

.chargeBackbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  width: 220px;
  height: 48px;
  background: #8e173e;
  border-radius: 4px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: 40px;
}

.ml {
  margin-left: 0.5rem;
}

@media only screen and (max-width: 801px) {
  .topTable {
    width: 100%;
  }

  .tableContent {
    background: #ffffff;
    padding-left: 20px;
    border-radius: 0px 0px 8px 8px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .customerInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 14px 0px;
  }
}
