.modalwrapper {
	width: 450px;
	min-height: 509px;
	background: #ffffff;
	z-index: 1000;
}
.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
	background: #ebf5f0;
}
.modalheadh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}
.dateWrapper {
}
.dateWrapper_p {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(10, 9, 3, 0.8);
}
.dateWrapper_content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.dateWrapper_contentbutton {
	width: 97px;
	height: 36px;
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	outline: none;
	border: none;
}

/* select starting stylings ------------------------------*/
.select {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #33322d;

	opacity: 0.8;
	position: relative;
	width: 100%;
	height: 55px;
}

.select_text {
	position: relative;
	font-family: inherit;
	background-color: transparent;
	width: 100%;
	height: 55px;

	padding: 10px 10px 10px 10px;
	font-size: 18px;
	border-radius: 0;
	border: none;
	border: 1px solid rgba(0, 0, 0, 0.12);
}

/* Remove focus */
.select_text:focus {
	outline: none;
	border: 1px solid rgba(0, 0, 0, 0.12);
}

/* Use custom arrow */
.select .select_text {
	appearance: none;
	-webkit-appearance: none;
}

.select:after {
	position: absolute;
	top: 18px;
	right: 10px;
	/* Styling the down arrow */
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid rgba(0, 0, 0, 0.12);
	pointer-events: none;
}

/* LABEL ======================================= */
.select_label {
	color: rgba(0, 0, 0, 0.26);
	font-size: 18px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	left: 10px;
	top: -10px;
	transition: 0.2s ease all;
	z-index: 1000;
	background: white;
}

/* active state */
.select_text:focus ~ .select_label,
.select_text:valid ~ .select_label {
	color: #33322d;
	top: -10px;
	transition: 0.2s ease all;
	font-size: 14px;
}

/* BOTTOM BARS ================================= */
.select_bar {
	position: relative;
	display: block;
	width: 100%;
}

.select_bar:before,
.select_bar:after {
	content: '';
	height: 2px;
	width: 0;
	bottom: 1px;
	position: absolute;
	background: #8E173E;
	transition: 0.2s ease all;
}

.select_bar:before {
	left: 50%;
}

.select_bar:after {
	right: 50%;
}

/* active state */
.select_text:focus ~ .select_bar:before,
.select_text:focus ~ .select_bar:after {
	width: 50%;
}

/* HIGHLIGHTER ================================== */
.select_highlight {
	position: absolute;
	height: 60%;
	width: 100px;
	top: 0;
	left: 0;
	pointer-events: none;
	opacity: 0.5;
}
.buttonwrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 64px;
}

.Downloadbutton {
	width: 100%;
	height: 55px;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	outline: none;
	border: none;
	margin-left: 15px;
}

.Downloadbutton_faint {
	width: 100%;
	height: 55px;
	background: rgba(170, 213, 188, 0.3);
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #8E173E;
	outline: none;
	border: none;
}
.Downloadbutton_span {
	margin-right: 8px;

	img {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
