.transactionInfo {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	width: 50%;
	justify-content: flex-start;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 20px;
}

.topTable {
	// width: 50%;
	margin: 0 auto;
	margin-top: 40px;
}

.detailsHeader {
	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	padding: 10px 20px;
}

.tableContent {
	background: #ffffff;
	padding-left: 20px;
	border-radius: 0px 0px 8px 8px;
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.customerInfo {
	display: flex;
	// align-items: center;
	flex-direction: column;
	padding: 14px 0px;
}

.detailsValue {
	font-family: 'HelveticaNeue';
	font-style: normal;
	// width: 40%;
	justify-content: flex-start;
	font-weight: bold !important;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.detailsKey {
	font-family: 'HelveticaNeue';
	font-style: normal;
	// width: 60%;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
	word-break: break-all;
}

.chargeBack {
	// display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.chargeBackbutton {
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	width: 220px;
	height: 48px;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 20px;
	margin-top: 40px;
}

.ml {
	margin-left: 0.5rem;
}

.merchantDispute {
	background: #ffffff;
	padding: 20px;
	margin: 30px 0;
	border-radius: 0px 0px 8px 8px;
	height: 500px;
	overflow: auto;

	h3 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		// width: 60%;
		justify-content: flex-start;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		word-break: break-all;
	}

	.merchantDisputeItemWrapper {
		// border: 1px solid red;
		color: inherit;
	}

	.merchantDisputeItem {
		border: 1px solid #888787;
		margin: 10px 0;
		padding: 20px;
		border-radius: 10px;
	}

	.merchantDisputeItemActor {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		border-radius: 10%;
		border: 1px solid #424242;
		padding: 1px 5px
	}

	.merchantDisputeItemComment {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		padding: 1px 0px
	}

	.merchantDisputeItemTime {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		padding: 1px 0px
	}
}

.chargeBackButtonWrapper {
	display: flex;
	gap: 30px
}

@media only screen and (max-width: 801px) {
	.topTable {
		width: 100%;
	}

	.tableContent {
		background: #ffffff;
		padding-left: 20px;
		border-radius: 0px 0px 8px 8px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.customerInfo {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 14px 0px;
	}
}