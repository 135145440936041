.webhooks {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;
  margin: 8px auto;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #8E173E;
    // border: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #8E173E;
    cursor: pointer !important;
    padding: 4px 12px;
  }

}