.topField {
  p {
    font-weight: bold;
    margin-bottom: 0;
    font-size: large;
  }
  width: 100%;
  margin: 10px 0px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dashbordContainer {
  margin: 20px 5rem;
}

.balance {
  margin-top: 16px;

  .balanceCardTwo {
    margin-top: 16px;
  }
}

.dashboardChart {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  height: 100%;
  padding: 24px;
}

.title {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.011em;
  color: #424242;
}

.description {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #616161;
  margin-top: -0.3rem;
}

.flexCard {
  display: flex;
  padding: 0.5rem;

  .left {
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: flex-start;
  }

  .right {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #0a0a0a;
    span {
      font-family: "HelveticaNeue";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.011em;
      color: #0a0a0a;
    }
  }

  .logo {
    background: #f5f5f5;
    border-radius: 360px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .currency {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-right: 0.5rem;

    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.006em;
    color: #424242;
  }

  .name {
    margin-left: 1rem;
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #0a0a0a;
  }
}

.container {
  margin-bottom: 100px;
}
.tableWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  width: 100%;
  margin: 20px auto auto;
  grid-gap: 20px;
}
.tableContainer {
  width: 100%;
  height: auto;
  min-height: 320px;
  background: #fbfefd;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  & > div:nth-child(2) {
    margin-top: 34px;
  }
}
.header {
  height: 68px;
  width: 100%;
  padding: 30px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}
.listWrapper {
  padding: 5px 25px;
  & > .listItem {
    height: auto;
    min-height: 80px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  & > .listItem:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & > span {
        margin-bottom: 4px;
      }
    }
  }
}
.deviceContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  // padding: 0 30px;
  & > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h4 {
      margin-top: 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.272307px;
      color: #0f1747;
      margin: 0;
      padding: 0;
    }
  }
  & > div:last-child {
    & > h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.272307px;
      color: #0f1747;
      margin: 0;
      padding: 0;
    }

    & > .listStatus {
      display: flex;
      align-items: center;
      & > p {
        margin-left: 4px;
        margin: 0;
        padding: 0;
      }
    }
    & > div {
      & > div {
        display: flex;
        align-items: center;
        & > div:first-child {
          background-color: #27ae60;
        }
        & > div {
          margin-right: 6px;
        }
      }
      &:nth-child(2) {
        & .status {
          background-color: #406a99;
          margin-right: 6px;
        }
      }
      &:last-child {
        & .status {
          background-color: #56ccf2;
          margin-right: 6px;
        }
      }
    }
  }
}

.headerh2 {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0a0903;
}
.headerSpan {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(10, 9, 3, 0.7);
}
.weekDaysContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-auto-rows: 20px;
  grid-row-gap: 10px;
  padding: 20px 23px;
  & > div {
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
    grid-gap: 20px;
    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: #828282;
      margin: 0;
      padding: 0;
    }
    & > div {
      max-height: 14px;
      & > div {
        max-height: 14px;
        background: rgba(111, 207, 151, 0.65) !important;
      }
      margin: 0 !important;
      width: 100%;
    }
  }
}
.paymentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 14px;
    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: #828282;
      margin-bottom: 6px;
    }
    & > div {
      & > div {
        max-height: 22px;
        & > div {
          color: #333333 !important;
        }
      }
      margin: 0 !important;
      width: 100%;
    }
  }
}

.progressBar {
  padding: 0 10px;
}
.transactionContainer {
  width: 95%;
  margin-right: auto;
  counter-reset: item;
  list-style: none;
  & > li {
    counter-increment: item;
    margin-top: 20px;
    &::before {
      content: counter(item);
      margin-right: 10px;
      background: #eb5757;
      border-radius: 2px;
      color: white;
      width: 1.2em;
      text-align: center;
      display: inline-block;
    }
  }
}
.puppleStatus {
  background-color: #bb6bd9 !important;
}
.orangeStatus {
  background-color: #ff9c72 !important;
}
.dangerStatus {
  background-color: #eb5757 !important;
}
.successsStatus {
  background-color: #6fcf97 !important;
}
.skyBlueStatus {
  background-color: #56ccf2;
}
.successBar {
  max-height: 22px;
  width: 100%;
  & > div {
    max-height: 22px;
    width: 100%;
    background: rgba(111, 207, 151, 0.71) !important;
  }
}
.dangerBar {
  & > div {
    background: rgba(235, 87, 87, 0.71) !important;
  }
}
.primaryBar {
  & > div {
    background: rgba(86, 204, 242, 0.69) !important;
  }
}
.status {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
ol {
  & > li {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #4f4f4f;
  }
}

@media (max-width: 600px) {
  .dashbordContainer {
    margin: 0.5rem 0;
  }

  .flexCard {
    display: block !important;
    padding: 0.5rem;
  }

  .left {
    width: 100% !important;
  }

  .right {
    width: 100% !important;
    span {
      font-family: "HelveticaNeue";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.011em;
      color: #0a0a0a;
    }
  }
}
