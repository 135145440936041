.settlementBody {
	margin: 0 2rem;
	font-family: 'HelveticaNeue';
	font-style: normal;
	line-height: 24px;
	letter-spacing: -0.011em;

	.card {
		height: 168px;
		background: #ffffff;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 12px;
		padding: 24px;
	}

	.sectionTop {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		flex-direction: row;
		width: 100%;
	}

	.topSections {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.bold {
		font-weight: 600;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		/* identical to box height, or 133% */

		letter-spacing: -0.011em;

		/* Neutral/100 */

		color: #0a0a0a;
	}

	.header {
		font-size: 18px;
		font-weight: 500;
		color: #0a0a0a;
		margin-top: 0.2rem;
	}

	.currency {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.006em;
		color: #424242;
	}

	.title {
		text-align: left;
		font-weight: 600;
		font-size: 18px;
		color: #0a0a0a;
	}

	.description {
		font-weight: 500;
		font-size: 15px;
		color: #424242;
		display: inline;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		/* identical to box height, or 133% */

		letter-spacing: -0.006em;

		/* Neutral/90 */

		color: #424242;
	}

	.icon {
		// margin-left: 0.5rem;
		text-align: right;
	}

	.sectionBottom {
		text-align: left;
		margin-top: 1rem;
		align-items: center;

		.icons {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: flex-start;
			margin-bottom: 8px;
		}

		.text {
			margin-left: 0.5rem;
			font-weight: 500;
			font-size: 16px;
			color: #424242;
			align-items: baseline;
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: -0.011em;
		}
	}

	.viewButton {
		background: #edfaf1;
		border-radius: 12px;
		text-align: center;
		padding: 14px 0px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-start;
		margin-top: 20px;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #016e20;
		cursor: pointer;

		& span {
			padding: 0 12px;
		}
	}

	.chargeBack {
		width: 200px;
		height: 40px;
		background: #8E173E;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		& span {
			padding: 0 3px;
		}
	}

	.createLink {
		width: fit-content;
		height: 40px;
		background: #8E173E;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		padding: 10px;
		cursor: pointer;

		.link {
			margin-right: 10px;
		}

		.mt1 {
			margin-top: 1rem;
		}
	}
}

.menuContent {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	flex-direction: row;
	cursor: pointer;

	.optionName {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		padding-left: 11px;
	}
}

@media only screen and (max-width: 600px) {
	.settlementBody {
		margin: 0;
		width: 90vw;
	}

	.topSections {
		display: block !important;
	}

	.chargeBack {
		margin: 0.5rem 0;
	}
}
