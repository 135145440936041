.transactionInfo {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	width: 50%;
	justify-content: flex-start;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.topTable {
	width: 50%;
	margin: 0 auto;
	margin-top: 40px;
}

.detailsHeader {
	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	padding: 10px 20px;
}

.tableContent {
	background: #ffffff;
	padding-left: 20px;
	border-radius: 0px 0px 8px 8px;
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
}

.customerInfo {
	display: flex;
	align-items: center;
	flex-direction: row;
	padding: 14px 0px;
}

.detailsValue {
	font-family: 'HelveticaNeue';
	font-style: normal;
	width: 40%;
	justify-content: flex-start;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.detailsKey {
	font-family: 'HelveticaNeue';
	font-style: normal;
	width: 60%;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.chargeBack {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.chargeBackbutton {
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	width: 220px;
	height: 48px;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 20px;
	margin-top: 40px;
}

.ml {
	margin-left: 0.5rem;
}

@media only screen and (max-width: 801px) {
	.topTable {
		width: 100%;
	}
}
