.topTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.detailsHeader {
  background: #ededed;
  border-radius: 8px 8px 0px 0px;
  font-family: "HelveticaNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
  padding: 10px 20px;
}

.updateButton {
  border: none;
  background-color: transparent;
  font-size: 13px;
  vertical-align: super;
  color: #8e173e;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
}

.configureNewGroup {
  border: 1px solid #8e173e;
  background-color: transparent;
  font-size: 13px;
  vertical-align: super;
  color: #8e173e;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 5px;
}

.tableContent {
  background: #ffffff;
  padding-left: 20px;
  border-radius: 0px 0px 8px 8px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
}

.customerInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 34px 0px;
}

.subsidiaryGroupWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 34px 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    border-bottom: 1px solid #8e173e;
    border-radius: 2%;
  }
}

.subsidiaryGroupWrapper2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 34px 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    border-bottom: 1px solid #8e173e;
    border-radius: 2%;
  }
}

.detailsValue {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #5c5b57;
}

.detailsKey {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a0903;
  word-break: break-all;
}

.transactionsHeader {
  font-family: "HelveticaNeue";

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
  margin-top: 38px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailBox {
  background: white;
  margin-bottom: 24px;
}

.detailh3 {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0a0903;
  display: flex;
  align-items: center;
}

.detailBoxCorner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
}

.detailicon {
  cursor: pointer;
}

.fileview {
  cursor: pointer;
  display: inline-block;
}

.accept {
  background: #f1f8f4;
  border-radius: 8px;
  padding: 20px;
  width: 65%;

  h4 {
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #070602;
    margin: 0;
    padding: 0;
    margin-right: 5px;
  }

  h3 {
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0a0903;
    margin: 0;
    padding: 0;
  }
}

.accept_top {
  display: flex;
  align-items: center;
}

.downloadbutton {
  padding: 11px 24px;
  height: 40px;
  background: #8e173e;
  border-radius: 4px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #969696;
  }
}

.buttonWrapper {
  display: flex;
  gap: 20px;
}

.uploadFileResponse {
  margin: 20px 0;
}

.uploadFileResponsesuccess {
  color: green;
}

.uploadFileResponsefailed {
  color: red;
}

.fileflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.modalwrapper {
  width: 518px;
  // height: 574px;
  background: #ffffff;
}

.modalwrapperFull {
  width: 100%;
  // height: 574px;
  background: #ffffff;
}

.modalhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 32px;
}

.modalheadh3 {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a1901;
}

.modalcontent {
  padding: 34px 32px;
  width: 100%;
}

.fileInputWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}

.deletefileBtn {
  height: 100%;
  margin-bottom: 30px;
}

.modalcontentp {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272501;
}

.modalcontentbox {
  margin-top: 29px;
  padding: 17px 30px;
  background: #f5faf7;
  border-radius: 5px;
  margin-bottom: 60px;
}

.modalcontentboxflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalcontentboxp {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #272501;
}

.modalselect {
  width: 100%;
  margin-bottom: 21px;
}

.select {
  width: 100%;
}

.inputLabel {
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.cancel {
  width: 84px;
  height: 40px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8e173e;
  margin-right: 16px;
  outline: none;
  border: 1px solid rgb(238, 238, 238);
  background: #ffffff;
  border-radius: 4px;
}

.fund {
  padding: 11px 24px;
  height: 40px;
  background: #8e173e;
  border-radius: 4px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #969696;
  }
}

.addMoreFileButton {
  padding: 10px 20px;
  height: 40px;
  border-radius: 4px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8e173e;
  outline: none;
  background-color: transparent;
  border: 1px solid #969696;
  cursor: pointer;

  &:disabled {
    background-color: #969696;
  }
}

.input {
  width: 100%;
  margin-bottom: 29px;
}

// code for subadditionalpreferences
.preferencesBody {
}

.accounts {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px 0;
  width: 100%;
}

.accountInfo {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
}

.filledaccountInfo {
  font-family: "Sarabun";
  font-style: normal;
  letter-spacing: -0.011em;
  margin: 30px 0px;
  background-color: white;
  width: 100%;
  height: fit-content;
  padding: 5px 10px;
  color: #0a0a0a;
  border-radius: 15px;
}

.accountEarnings {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #616161;
  margin-top: 4px;
}

.secondhand {
  min-width: 682px;
  // background: #ffffff;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 24px;
}

.paymentMethods {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  margin-top: 30px;

  .accountInfo {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #0a0a0a;
  }

  .accountEarnings {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #616161;
    margin-top: 4px;
  }
}

.radioSelection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioLabel {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  color: #424242;
}

.radioSelection_one {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;

  .radioLabel {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #424242;
  }
}

.paymentList {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .checkboxContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .checkboxContentFa {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
  }

  // .checkboxContent:nth-child(n + 1):nth-child(-n + 3) {
  // 	border-bottom: 1px solid #e0e0e0;
  // 	padding-top: 8px;
  // 	padding-bottom: 8px;
  // }
  .checkboxLabel {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #424242;
  }
}

/*Mobile View */
@media (max-width: 1024px) {
  .accounts {
    flex-direction: column;
  }
}

// code for subadditionalpreferences
