.wrapper {
  padding: 36px 40px;
  width: 100%;
}
.wrapperInner {
  display: flex;
  width: 100%;
}
.wrapperInnerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7f3ec;
  border-radius: 14px;
  margin-right: 29px;
  width: 94px;
  height: 26px;
  cursor: pointer;

  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #272501;
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }
}
.wrapperInnerRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapperh2 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #8E173E;
}
.wrapperp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4b492b;
  margin-bottom: 35px;
  width: 567px;
}
//STOPS
.inputwrapper {
  padding: 25px 33px;
  width: 539px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 35px;
}
.submitHandler {
  width: 84px;
  height: 40px;
  background: #8E173E;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: #b98055;
  }
}
.flexInput {
  width: 100%;
  display: flex;
  align-items: center;
}

.input {
  height: 54px;
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
}
.inputText {
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
}

.buttonflex {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cancel {
  width: 84px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  outline: none;
  border: 1px solid #8E173E;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8E173E;
  margin-right: 11px;
}

.formH3 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #070602;
}

.imagelogo {
  width: 94px;
  height: 94px;
  border-radius: 4px;
  object-fit: cover;
}

.logowrapper {
  display: flex;
  margin: 28px 0;

  img {
    margin-right: 11px;
  }
  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4b492b;
  }
}

.preview {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.397);
}
.previewwrap {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	position: relative;
}
// Remove icon
.removeButton {
	position: absolute;
	bottom: -10px;
	right: 5px;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.406);
	color: rgba(255, 0, 0, 0.814);
	z-index: 10; 
	font-weight: 900;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	font-size: 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;	  
}

/*Mobile View */
@media (max-width: 768px){
	.wrapper{
		padding: 2px 3px;
	}
	.inputwrapper {
		width: 100%;
	}
	.wrapperInner {
		flex-direction: column;
	}
	.wrapperInnerLeft {
		background: #8E173E;
		color: white;
		width: fit-content;
		padding: 4px 14px;
		cursor: pointer;
		p{color: white;}
		margin-bottom: 16px;
	}
	.wrapperp{
		width: auto;
	}
}