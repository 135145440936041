.menuContent {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	flex-direction: row;
	cursor: pointer;

	.optionName {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		padding-left: 11px;
	}
}
// scroll actions
.scrolwrapper {
	overflow-y: auto;
	padding: 10px 10px;
	max-height: 350px;
}
@keyframes pulse {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.3);
	}
	100% {
	  transform: scale(1);
	}
}
  
.pulseIcon {
	position: absolute;
	color: #83173E;
	right: 0px;
	bottom: 10px;
	animation: pulse 1.5s infinite;
}

  
.createLink {
	width: fit-content;
	height: 40px;
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	padding: 10px;
	cursor: pointer;

	.link {
		margin-right: 10px;
	}

	.mt1 {
		margin-top: 1rem;
	}
}

.businessActionButtons {
	display: flex;
	gap: 10px;
}

.mt0 {
	margin-top: 0.5rem;
}

.mt1 {
	margin-top: 2rem;
}

.dashbordContainer {
	margin: 40px 0px;
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 38px;
	margin-bottom: 24px;
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	align-items: center;
}
//For 'view options dropdown' 
.dropdown {
	position: relative;
	display: inline-block;
	padding: 4px;
	width: auto;
	margin: 0px 30px;
  }
  
  .dropbtnBox{
	  width: 100%;
	  background-color: transparent;
	  display: flex;
	  align-self: center;
	  justify-content: end;
  }
  .dropbtn {
	background-color: #8E173E;
	color: white;
	padding: 8px 16px;
	border-radius: 5px;
	margin-bottom: 5px;
	border: none;
	cursor: pointer;
  }
  
  .dropbtn:hover,
  .dropbtn:focus {
	border: 0.5px solid white;
  }
  
  .dropdownContent {
	display: none;
	position: absolute;
	margin-top: 5px;
	background-color: #e6d1d8;
	border-radius: 5px;
	min-width: 200px;
	width: 100%;
	z-index: 1;
	padding: 4px 8px;
  }
  .dropdownContent .dropdownContentText {
	color: black;
	font-weight: 500;
	padding: 8px; /* Adjusting padding for proper spacing */
	text-decoration: none;
	display: block;
	cursor: pointer;
  }
  
  .dropdownContent .dropdownContentText:hover {
	font-weight: 700;
	border-bottom: 1px solid #8E173E;
  }
  
  .dropdown .dropdownContent {
	display: block;
  }
  
.dashboardChart {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	height: 100%;
	padding: 24px;
}

.filterSubheading {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	cursor: pointer;
}

.filterBody {
	margin-top: 10px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
	cursor: pointer;
}

.mb {
	margin-bottom: 1rem;
}

.detailsHeader {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
}

.detailsSubHeader {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

@media only screen and (max-width: 600px) {
	.dashbordContainer {
		margin: 0.5rem 0;
	}
}


/* Modal Styles */
.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .modalContent {
	background: white;
	padding: 20px;
	border-radius: 8px;
	width: 80%;
	// max-width: 600px;
	max-height: 80%;
	overflow-y: auto;
	height: 800px;
  }
  .closeButton {
	background: none;
	border: none;
	font-size: 25px;
	cursor: pointer;
	color: red;
	font-weight: bolder;
  }
  .transactionInfo {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	width: 50%;
	justify-content: flex-start;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.topTable {
	// width: 50%;
	margin: 0 auto;
	margin-top: 40px;
}

.detailsHeader {
	display: flex;
	justify-content: space-between;
	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	padding: 10px 20px;
}

.tableContent {
	background: #ffffff;
	padding-left: 20px;
	border-radius: 0px 0px 8px 8px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.customerInfo {
	display: flex;
	// align-items: center;
	flex-direction: column;
	padding: 14px 0px;
}

.detailsValue {
	font-family: 'HelveticaNeue';
	font-style: normal;
	// width: 40%;
	justify-content: flex-start;
	font-weight: bold !important;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.detailsKey {
	font-family: 'HelveticaNeue';
	font-style: normal;
	// width: 60%;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
	word-break: break-all;
}

.chargeBack {
	display: flex;
	gap: 30px;
	// justify-content: center;
	align-items: center;
	width: 100%;
}

.chargeBackbutton {
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	width: 220px;
	height: 48px;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 20px;
	margin-top: 40px;
}

.ml {
	margin-left: 0.5rem;
}

@media only screen and (max-width: 801px) {
	.topTable {
		width: 100%;
	}

	.tableContent {
		background: #ffffff;
		padding-left: 20px;
		border-radius: 0px 0px 8px 8px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.customerInfo {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 14px 0px;
	}
}
