.pageTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;
	margin-bottom: 24px;
}

.transactions {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #8E173E;
}

.actions {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-around;
}

.newH3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.actionButtons {
	display: flex;
	align-items: center;
}

.filter {
	background: #ffffff;
	border: 1px solid #0069D0;
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	height: 40px;
	width: 77px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.006em;
	color: #424242;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	position: relative;

	.dropArrow {
		padding-left: 4px;
	}
}

@media only screen and (max-width: 801px) {
	.pageTop {
		display: block;
	}

	.actions {
		margin-top: 0.5rem;
		display: block;
	}

	.actionButtons {
		display: flex;
	}

	.headerButton {
		margin-top: 1.5rem;
		margin-left: -1rem;
	}
}
