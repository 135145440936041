.sidewrapper {
	height: 100vh;
	overflow-y: scroll;
}

.sidewrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.sidewrapper {
	-ms-overflow-style: none;
	scrollbar-width: none;
	/* Firefox */
}

.logowrapper {
	background-color: #dfdfdf;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 50px;
	margin-top: 17px;
}

.logo {
	width: 150px;
	// height: 27px;
	object-fit: cover;
}

.logocontent {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	color: #0a0903;
}

.menuwrapper {
	display: flex;
	flex-direction: column;
	padding: 0 16px;
}

.menuIndividual {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 4px;
	margin-bottom: 8px;
	height: 44px;
	cursor: pointer;
}

.menuActiveIndividual {
	display: flex;
	align-items: center;
	background: #F0F9FF;
	border-radius: 4px;
	margin-bottom: 8px;
	height: 44px;
	cursor: pointer;
}

.icon {
	margin-right: 15px;
	padding-left: 19px;
}

.iconActive {
	margin-right: 15px;
	border-left: 3px solid #8E173E;
	padding-left: 19px;
	color: #8E173E;
}

.activeMenu {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: #8E173E;
}

.activeMenuNested {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: #8E173E;
	border-left: 3px solid #8E173E;
	padding-left: 50px;
}

.menuItem {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: rgba(10, 9, 3, 0.8);
}

.menuItemNested {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: rgba(10, 9, 3, 0.8);
	padding-left: 50px;
}

.activeColor {
	display: flex;
}

.activeColorText {}

.inactiveColorText {}

.close {
	display: flex;
	align-items: center;
	padding-left: 36px;
	height: 44px;
	cursor: pointer;
}

.closeSign {
	display: flex;
	align-items: center;
	padding-left: 36px;
	cursor: pointer;
	margin-top: 88px;
}

.closeIcon {
	margin-right: 15px;
}

.closeContent {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: rgba(10, 9, 3, 0.8);
	text-transform: capitalize !important;
}

.flexwrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}