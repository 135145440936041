.menuContent {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  .optionName {
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #424242;
    padding-left: 11px;
  }
}

.createLink {
  width: fit-content;
  height: 40px;
  background: #8e173e;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.011em;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  padding: 10px;
  cursor: pointer;

  .link {
    margin-right: 10px;
  }

  .mt1 {
    margin-top: 1rem;
  }
}

.mt0 {
  margin-top: 0.5rem;
}

.mt1 {
  margin-top: 1rem;
}

.dashbordContainer {
  margin: 0 5rem;
}

.dashboardChart {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  height: 100%;
  padding: 24px;
}

.filterSubheading {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
  cursor: pointer;
}

.filterBody {
  margin-top: 10px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #424242;
  cursor: pointer;
}

.mb {
  margin-bottom: 1rem;
}

.detailsHeader {
  font-family: "HelveticaNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #616161;
}

.detailsSubHeader {
  font-family: "HelveticaNeue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
}
.busiCompWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.busiCompWrapperImg {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 12.95px;
    height: 15.22px;
  }
}

.busiCompWrapperp {
  margin: 0;
  padding: 0;
}

.Downloadbutton {
  width: 100%;
  height: 55px;
  background: #8e173e;
  border-radius: 4px;
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  outline: none;
  border: none;
  margin-top: 60px;
}

.Downloadbutton_span {
  margin-right: 8px;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.customerInfoSingle {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.modalwrapper {
  width: 518px;
  min-height: 400px;
  background: #ffffff;
  height: fit-content;
  max-height: 50vh;
  overflow: scroll;
}
.modalhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 32px;
}
.modalheadh3 {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a1901;
}
.inputField {
  color: #757575 !important;
  font-size: 12px;

  & input,
  textarea {
    padding: 1rem !important;
    color: #757575 !important;
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #424242 !important;
  }

  & label {
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.006em;
    color: #424242;
    z-index: 1000;
  }
}

@media only screen and (max-width: 600px) {
  .dashbordContainer {
    margin: 0.5rem 0;
  }
}
