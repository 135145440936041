.createLink {
    width: 168px;
    height: 40px;
    background: #8E173E;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
    .link {
      margin-right: 10px;
    }
  }
  .hello {
    height: 200px;
    width: 200px;
    background-color: #8E173E;
    color: #ffffff;
  }
  .menuContent {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
  
    .optionName {
      font-family: 'Sarabun';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.011em;
      color: #424242;
      padding-left: 11px;
    }
  }
  
  .declined {
    background: #ffecec;
    border-radius: 400px;
    width: fit-content;
    padding: 4px 12px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.006em;
    color: #e00000;
    text-transform: capitalize;
  }
  
  .processing {
    border-radius: 400px;
    width: fit-content;
    padding: 4px 12px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff7ed;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.006em;
    color: #9e5400;
    text-transform: capitalize;
  }
  
  .completed {
    border-radius: 400px;
    width: fit-content;
    padding: 4px 12px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebf5f0;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.006em;
    color: #8E173E;
    text-transform: capitalize;
  }
  
  .filterSubheading {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #0a0a0a;
  }
  
  .filterBody {
    margin-top: 10px;
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #424242;
  }
  .button{
    width: fit-content;
    height: auto;
    padding: 10px 13px;
    margin: 10px 0px;
    border-radius: 4px;
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    outline: none;
    border: none;
  }
  .enabledButton {
    background: #8E173E;
    color: white;
  }
  
  .disabledButton {
    background: gainsboro;
    cursor: not-allowed;
    color: black;
  }
  .modalselect{
    width: auto;
    max-width: 300px;
    height: fit-content;
  }
  .select{
    width: auto;
    height: fit-content;
    padding: 5px 0;
    box-sizing: border-box; 
  }