.rolesBody {
	width: 475px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	// overflow-y: scroll;
	margin: 30px auto;
}

.roles {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 30px 0px;
	width: 475px;
	margin: 42px auto;

	.mt1 {
		margin-top: 1rem;
	}

	.mt2 {
		margin-top: 2rem;
	}

	.rolesHeader {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #0a0903;
		margin: 0;
		padding: 0;
		padding: 0px 30px;
	}

	.rolesInfo {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: rgba(10, 9, 3, 0.6);
		border-bottom: 1px solid rgba(206, 206, 205, 0.4);
		padding: 10px 30px;
	}

	.rolesDescription {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #616161;
	}

	.permissionsList {
		margin-top: 5px;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;

		.permissionsItems {
			padding: 10px 30px;
			border-bottom: 1px solid #e0e0e0;
			display: flex;
			align-items: center;
		}

		.permissionsItemsTwo {
			padding: 10px 30px;
			display: flex;
			align-items: center;
		}

		.rolesLabel {
			width: 90%;
		}

		.mark {
			width: 10%;
			text-align: right;
		}
	}
}

.rolesButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 193px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	cursor: pointer;

	& span {
		padding-left: 10px;
	}
}

.editButton {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 108px;
	height: 36px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #ffffff;
	cursor: pointer;
	outline: none;
	border: none;

	& span {
		padding-left: 10px;
	}
}

.buttonend {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 0 30px;
}

.sidenav {
	width: 300px;

	.sidenavHeader {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
		margin-top: 150px;
	}

	.sideItem {
		padding: 8px 0;
		text-decoration: none;
		display: block;
		font-family: 'HelveticaNeue', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
	}

	.activeSideItem {
		padding: 8px 0;
		text-decoration: none;
		display: block;
		font-family: 'HelveticaNeue', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #008243;
	}
}

.sideItem:hover {
	color: #008243;
}

.createLink {
	width: 200px;
	height: 40px;
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	cursor: pointer;
	.link {
		margin-right: 10px;
	}
}

.createLinkRole {
	width: 200px;
	height: 40px;
	background: rgba(170, 213, 188, 0.3);
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #001a0b;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	cursor: pointer;
	.link {
		margin-right: 10px;
	}
}

.setting_h4 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	color: #101828;
}
.setting_box {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 43, 18, 0.08),
		0px 1px 2px rgba(0, 43, 18, 0.06);
	border-radius: 8px;
	padding: 24px;
	max-width: 1400px !important;
	width: 100%;
	margin: 40px 0px;
}
.setting_h5 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
	max-width: 753px;
	width: 100%;
}
.setting_p {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #33322d;
}
.singlewrapper {
	background: #ffffff;
	border: 1px solid rgba(206, 206, 205, 0.4);
	box-shadow: 0px 1px 2px rgba(0, 43, 18, 0.08);
	border-radius: 8px;
	padding: 20px;
	width: 266px !important;
	height: 105px;
	margin-right: 20px;
	cursor: pointer;

	p {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		color: #8E173E;
		margin: 0;
		padding: 0;
	}
	h5 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #0a0903;
		margin: 0;
		padding: 0;
	}
}

.singleTop {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	width: 100%;

	p {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 0.01em;
		margin: 0;
		padding: 0;
	}
}

.settingsinglebox {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: scroll;
	// width: 100%;
	-ms-overflow-style: none;  
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
}

.transactionsHeader {
	font-family: 'HelveticaNeue';

	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 38px;
	margin-bottom: 24px;
}

@media only screen and (max-width: 901px) {
	.rolesBody {
		// width: 90vw;
		margin: 0;
		display: block;
	}

	.sidenav {
		position: relative !important;
		top: 0;
		left: 0;
		text-align: center;
		display: block;
		margin: 0 auto;
		z-index: 0;
	}

	.rolesButton {
		width: 100%;
	}

	.rolesButton span {
		display: none;
	}

	.rolesButton:after {
		content: 'Create role';
		margin-left: 1rem;
	}

	.customRole {
		margin-left: 1rem;
	}

	.roles {
		width: auto;
		margin: 1rem;
	}
}
